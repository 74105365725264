import React from "react";
import { AppBar } from "react-admin";

import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    spacer: {
        flex: 1,
    },
    color: {
        backgroundColor: '#09732c'
    }
});

const CustomAppBar = (props: any) => {
    const classes = useStyles();

    return <AppBar {...props} className={classes.color}></AppBar>;
};

export default CustomAppBar;
