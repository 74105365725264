import React, { useState, useEffect } from "react";
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { 
    useRedirect, fetchEnd, fetchStart, SelectInput, NumberInput, Button, useNotify,
    Loading
} from 'react-admin';

import { 
    Card, CardHeader, CardContent, Box, Paper, TableContainer, Table, 
    TableHead, TableBody, TableRow, TableCell, Typography
} from '@material-ui/core';

import DownloadExcelButton from '../components/DownloadExcelButton';

import { FreeObject, DownloadLink } from '../types';
import { getURL, fetchAPI, useStyles } from '../utils';
import SurveyContext from '../contexts/survey';



type GroupedBy = "businessTotal" | "privateCarAndCompanyCarAndMotorcycle" | "publicTransport";
type Pollutant = "nox" | "co2" | "pm10";
type CurrentBudgetEnvironmentalImpacts = {
    [pollutant in Pollutant]: number
};
type CurrentBudgetResponse = {
    [groupedBy in GroupedBy]: {
        [dataName: string]: number | CurrentBudgetEnvironmentalImpacts,
        totYearKm: number,
        totTime: number,
        yearlyAverageExpense: number,
        yearlyAverageExpenseRecalculated: number,
        environmentalImpacts: CurrentBudgetEnvironmentalImpacts,
        roadAccidents: number
    } | undefined
};

const CurrentBudgetPage = () => {
    const dispatch = useDispatch();
    const notify = useNotify();
    // const [surveyName] = useState<string | null>(localStorage.getItem('surveyName'));
    const [currentBudgetData, setCurrentBudgetData] = useState<FreeObject>({});
    const [currentBudgetResponse, setCurrentBudgetResponse] = useState<CurrentBudgetResponse>({} as CurrentBudgetResponse);
    const [downloadLink, setDownloadLink] = useState<DownloadLink>({
        available: false,
        url: ''
    });
    const { survey } = React.useContext(SurveyContext);

    const surveyName = survey?.name || null;
    const surveyClosed = survey?.closed || false;

    const [loadingResults, setLoadingResults] = useState(false);
    
    const redirect = useRedirect();
    const classes = useStyles();

    const onChange = (e: any) => { 
        const value = e.target.type === 'number'? Number(e.target.value) : e.target.value;
        setCurrentBudgetData({ ...currentBudgetData, [e.target.name]: value });
    };

    const submit = async () => {
        dispatch(fetchStart());
        setLoadingResults(true);
        try {
            const response = await fetchAPI('currentBudget', null, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({...currentBudgetData, surveyName}),
            });
            const result = await response.json();
            setCurrentBudgetResponse(result);
            setDownloadLink({
                available: true,
                url: getURL('currentBudget/download-currentBudget-excel', { surveyName })
            });
        }
        catch (error: any) {
            notify(error, 'warning', undefined, false, 5000);
            console.error(error);
        }
        setLoadingResults(false);
        dispatch(fetchEnd());
    };

    useEffect(() => {
        const getData = async () => {
            const response = await fetchAPI('currentBudget', { surveyName });

            if(response.ok) {
                const data = await response.json();
                setCurrentBudgetData(data.commonData);
                setCurrentBudgetResponse(data.currentBudget);
                setDownloadLink({
                    available: true,
                    url: getURL('currentBudget/download-currentBudget-excel', { surveyName })
                });
            }
        }

        if (surveyName === null) 
            redirect("/change-propensity");
        else {
            setCurrentBudgetData({});
            getData();
        }
    }, [surveyName]);

    return (
        <Card>
            <CardHeader title="Bilancio attuale" />
            <CardContent>
                <Box key="form">
                    <Form onSubmit={submit} initialValues={{ ...currentBudgetData }}>
                        {
                            props => (
                                <form onSubmit={props.handleSubmit}>
                                    <h3>Seleziona aggregazione</h3>
                                    <SelectInput
                                        label="Seleziona"
                                        source="currentBudgetGroupBy"
                                        onChange={onChange}
                                        choices={[
                                            { id: 'businessTotal', name: 'Per totale azienda' },
                                            { id: 'vehicleType', name: 'Per tipo di mezzo usato' }
                                        ]}
                                        disabled={loadingResults || !surveyName || surveyClosed}
                                        required
                                    />
                                    <Box className={classes.root}>
                                        <NumberInput
                                            className={classes.field}
                                            label="N. settimane lavorate"
                                            source="workWeeks"
                                            onChange={onChange}
                                            min={0}
                                            step={1}
                                            disabled={loadingResults || !surveyName || surveyClosed}
                                            required
                                        />
                                        <NumberInput
                                            className={classes.field}
                                            label="€/km"
                                            source="euroPerKm"
                                            onChange={onChange}
                                            min={0}
                                            step={0.001}
                                            disabled={loadingResults || !surveyName || surveyClosed}
                                            required
                                        />
                                        <NumberInput
                                            className={classes.field}
                                            label="Numero di incidenti per km"
                                            source="roadAccidentsPerKm"
                                            onChange={onChange}
                                            min={0}
                                            step={0.001}
                                            disabled={loadingResults || !surveyName || surveyClosed}
                                            required
                                        />
                                    </Box>
                                    <Button label="Conferma" type="submit" size="large" variant="contained" disabled={loadingResults || !surveyName || surveyClosed} />
                                </form>
                            )
                        }
                    </Form>
                    { loadingResults && <Loading loadingPrimary="Attendi..." loadingSecondary="Elaborazione risultati in corso" /> }
                </Box>
                <Box className={classes.results} key="results">
                    {
                        downloadLink.available
                        && <DownloadExcelButton url={ downloadLink.url } />
                    }
                    {
                        Object.keys(currentBudgetResponse).map((groupedByString: string) => {
                            const groupedBy = groupedByString as GroupedBy;

                            const groupedByData = currentBudgetResponse[groupedBy];

                            let title = '';
                            
                            if(groupedByData === undefined){
                                return null;
                            }
                            
                            switch (groupedBy) {
                                case 'businessTotal':
                                    title = 'Totale azienda';
                                    break;
                                case 'privateCarAndCompanyCarAndMotorcycle':
                                    title = 'Totale Auto privata + Auto aziendale + Moto';
                                    break;
                                case 'publicTransport':
                                    title = 'Totale Trasporto pubblico';
                            }

                            return (
                                <Box className={classes.table} key={`table_${groupedBy}`}>
                                    <Typography component="h5" variant="h5">{title}</Typography>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow key={`head_row_${groupedBy}`}>
                                                    <TableCell variant="head">
                                                        <Typography>KM annui totali</Typography>
                                                    </TableCell>
                                                    <TableCell variant="head">
                                                        <Typography>Tempo annuo impiegato</Typography>
                                                    </TableCell>
                                                    <TableCell variant="head">
                                                        <Typography>Costo annuo dichiarato per spostamenti casa-lavoro (€)</Typography>
                                                    </TableCell>
                                                    <TableCell variant="head">
                                                        <Typography>Costo annuo ricalcolato per spostamenti casa-lavoro (€)</Typography>
                                                    </TableCell>
                                                    <TableCell variant="head">
                                                        <Typography>Calcolo impatti ambientali annui totali (Nox)</Typography>
                                                    </TableCell>
                                                    <TableCell variant="head">
                                                        <Typography>Calcolo impatti ambientali annui totali (CO2)</Typography>
                                                    </TableCell>
                                                    <TableCell variant="head">
                                                        <Typography>Calcolo impatti ambientali annui totali (PM10)</Typography>
                                                    </TableCell>
                                                    <TableCell variant="head">
                                                        <Typography>Incidentalità stradale (N.)</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow key={`body_row_${groupedBy}`}>
                                                    {
                                                        
                                                        Object.keys(groupedByData).map((dataName: string) => {
                                                            const data = groupedByData[dataName];
                                                            if (typeof data === 'object') {
                                                                return Object.keys(data).map((key: string) => {
                                                                    const environmentalImpact = key as keyof CurrentBudgetEnvironmentalImpacts;
                                                                    return (
                                                                        <TableCell key={key}>
                                                                            {data[environmentalImpact].toLocaleString('it', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                        </TableCell>
                                                                    );
                                                                });
                                                            }
                                                            else return (
                                                                <TableCell>
                                                                    {data.toLocaleString('it', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                </TableCell>
                                                            );
                                                        })
                                                    }
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            );
                        })
                    }
                </Box>
            </CardContent>
        </Card>
    );
}

export default CurrentBudgetPage;
