import React from "react";
import {
    Card, CardContent, CardHeader, Typography, Button
} from '@material-ui/core';

import { fetchAPI } from './utils';
import SurveyContext from "./contexts/survey";
import { SurveyContextType } from "./types";



const Dashboard = () => {
    const { survey, setSurvey } = React.useContext<SurveyContextType>(SurveyContext);

    const closeSurvey = async () => {
        const response = await fetchAPI('surveys', null, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ surveyName: survey?.name}),
        });

        if (response.ok)
            setSurvey(await response.json());
    };

    return (
        <Card>
            <CardHeader title="Bentornato" />
            <CardContent>
                {
                    survey ? <React.Fragment>
                        <Typography>Sondaggio attuale: {survey?.name}</Typography>
                        <Typography>Stato: {survey?.closed ? 'Chiuso' : 'Aperto'}</Typography>
                        {
                            survey?.closed === false
                            && <Button
                                variant="contained"
                                color="primary"
                                onClick={closeSurvey}
                            >
                                Chiudi
                            </Button>
                        }
                    </React.Fragment> : <Typography>Nessun sondaggio selezionato</Typography>
                }
                
            </CardContent>
        </Card>
    );
};

export default Dashboard;