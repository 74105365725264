import React, { useState, useEffect } from 'react';
import { Loading, useRedirect } from 'react-admin';

import {
    Card, CardContent, CardHeader, Box, Tabs, Tab,
    Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper,
    FormControl, InputLabel, MenuItem, Select, Button, CardActions, Typography
} from '@material-ui/core';

import Input from '../components/Input';
import DownloadExcelButton from '../components/DownloadExcelButton';

import { SelectedMeasuresType, ClusterType, InputValuesType, DownloadLink } from '../types';
import { fetchAPI, getURL, useStyles } from '../utils';
import SurveyContext from '../contexts/survey';

type Data = {
    [cluster: string]: {
        [key: string]: number
    };
};

type GroupByType = "company" | "vehicle" | "distance";

const groupByLabels: { [key in GroupByType]: string } = {
    "company": "Azienda",
    "vehicle": "Mezzo",
    "distance": "Distanza",
};

type BenefitType = "employees" | "company" | "collectivity";

type Benefit = {
    type: BenefitType;
    label: string;
    groupBy?: GroupByType[]
}

const benefits: { [n: number]: Benefit } = {
    0: {
        type: "employees",
        label: "Benefici per i dipendenti",
        /* groupBy: [
            "company",
            "vehicle",
            "distance",
        ] */
    },
    1: {
        type: "company",
        label: "Benefici per l'azienda",
    },
    2: {
        type: "collectivity",
        label: "Benefici per la collettività",
    },
};

type InputType = {
    clusters: ClusterType[] | 'all';
    label: string;
    name: string;
    inputType: "number" | "text" | "textarea" | "select";
    type?: "enginePoolingSharing" | "euroClassPoolingSharing" | "engineShuttle" | "euroClassShuttle";
    values?: InputValuesType[];
}

type BenefitInputsType = {
    [benefit in BenefitType]: {
        general?: InputType[];
        row?: InputType[];
    }
}

const benefitInputsInit: BenefitInputsType = {
    'company': {
        row: [
            {
                clusters: 'all',
                name: "Regolarità nell'arrivo dei dipendenti",
                label: "Regolarità nell'arrivo dei dipendenti",
                inputType: 'textarea',
            },
            {
                clusters: 'all',
                name: 'Maggiore dedizione al lavoro del personale',
                label: "Maggiore dedizione al lavoro del personale",
                inputType: 'textarea',
            },
            {
                clusters: 'all',
                name: 'Possibilità di riutilizzo di aree aziendali',
                label: 'Possibilità di riutilizzo di aree aziendali',
                inputType: 'textarea',
            },
            {
                clusters: 'all',
                name: "Introiti derivanti dall'eventuale tariffazione delle aree sosta aziendali",
                label: "Introiti derivanti dall'eventuale tariffazione delle aree sosta aziendali",
                inputType: 'textarea',
            },
            {
                clusters: 'all',
                name: "Possibilità di rafforzamento dell'immagine aziendale",
                label: "Possibilità di rafforzamento dell'immagine aziendale",
                inputType: 'textarea',
            },
        ]
    },
    'employees': {},
    'collectivity': {
        general: [
            // TODO: remove
            /* {
                clusters: ['Car Pooling', 'Car Sharing'],
                name: 'yearDaysPoolingSharingUse',
                label: 'N° gg annuali utilizzo servizio car sharing',
                inputType: 'number',
            }, */
            {
                clusters: ['Car Pooling', 'Car Sharing'],
                name: 'engineTypePoolingSharing',
                label: 'Alimentazione Pooling/Sharing',
                inputType: 'select',
                type: 'enginePoolingSharing',
            },
            {
                clusters: ['Car Pooling', 'Car Sharing'],
                name: 'euroClassPoolingSharing',
                label: 'Classe Euro Pooling/Sharing',
                inputType: 'select',
                type: 'euroClassPoolingSharing',
            },

            {
                clusters: ['Navetta aziendale'],
                name: 'totYearKmForShuttle',
                label: 'KM totali annui navetta',
                inputType: 'number',
            },
            {
                clusters: ['Navetta aziendale'],
                name: 'engineTypeShuttle',
                label: 'Alimentazione navetta',
                inputType: 'select',
                type: 'engineShuttle',
            },
            {
                clusters: ['Navetta aziendale'],
                name: 'euroClassShuttle',
                label: 'Classe Euro navetta',
                inputType: 'select',
                type: 'euroClassShuttle',
            },
        ]
    }
};

type BenefitValuesType = {
    [benefitInputKey: string]: string | number;
}


type a11yPropsType = {
    id: string;
    'aria-controls': string;
};

export function a11yProps(index: number): a11yPropsType {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export type AchievableBenifitsPayload = {
    surveyName: string | null
    type: BenefitType;
    groupBy?: GroupByType | '';
} & { [key: string]: string | number | null; };



const AchievableBenifits = (): JSX.Element => {
    const [value, setValue] = useState<number>(0);
    const [data, setData] = useState<Data>({} as Data);
    // const [surveyName] = useState<string | null>(localStorage.getItem('surveyName'));
    const [selectedBenefit, setSelectedBenefit] = useState<Benefit>(benefits[value]);
    const [selectedGroupBy, setSelectedGroupBy] = useState<GroupByType | ''>(selectedBenefit.groupBy?.[0] || '');
    const [benefitInputs, setBenefitInputs] = useState<BenefitInputsType>(benefitInputsInit);
    const [benefitInputValues, setBenefitInputValues] = useState<BenefitValuesType>({} as BenefitValuesType);
    const [benefitInputRowValues, setBenefitIntputRowValues] = useState<Data>({} as Data);
    const [selectedMeasures, setSelectedMeasures] = useState<SelectedMeasuresType>({} as SelectedMeasuresType);
    const [isCurrentBudgetSaved, setIsCurrentBudgetSaved] = useState<boolean>(true);
    const [downloadLink, setDownloadLink] = useState<DownloadLink>({
        available: false,
        url: ''
    });
    const { survey } = React.useContext(SurveyContext);

    const surveyName = survey?.name || null;
    const surveyClosed = survey?.closed || false;

    const [loading, setLoading] = useState<boolean>(false);
    const [isFirst, setIsFirst] = useState<boolean>(true);

    const redirect = useRedirect();
    const classes = useStyles();


    const fetchData = async (
        surveyName: string | null,
        type: BenefitType,
        groupBy: GroupByType | "",
        inputValues: BenefitValuesType
    ): Promise<void> => {

        setLoading(true);
        try {
            const payload: AchievableBenifitsPayload = { surveyName, type, groupBy, ...inputValues };
            const response = await fetchAPI('achievableBenefits', payload);
            // TODO: remove
            // console.log(type, fakeData)
            // setData(fakeData[type]);
            // return;
            if (response.ok) {
                const dataResponse = await response.json();

                if (Object.keys(dataResponse).length === 0)
                    redirect("/measures");

                setBenefitInputValues(dataResponse.commonData);

                delete dataResponse.commonData;

                setData(dataResponse);
            }
        } finally {
            setLoading(false);
        }

    };

    const saveData = async () => {
        setLoading(true);
        try {
            await fetchAPI('achievableBenefits/create/achivableBenefitsForCompany', null, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ surveyName, type: selectedBenefit.type, benefits: benefitInputRowValues }),
            });
        }
        finally {
            setLoading(false);
        }
    };


    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
        const benefit: Benefit = benefits[newValue];
        setSelectedBenefit(benefit);
        setSelectedGroupBy(benefit.groupBy?.[0] || '');
        setBenefitInputValues({} as BenefitValuesType);
        setBenefitIntputRowValues({} as Data);
        setData({});
    };

    const handleGroupBy = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedGroupBy(event.target.value as GroupByType);
    };

    const submitInputs = () => {
        fetchData(surveyName, selectedBenefit.type, selectedGroupBy, benefitInputValues);
    };


    useEffect(() => {
        setData({} as Data);
        if (surveyName) {
            fetchData(surveyName, selectedBenefit.type, selectedGroupBy, benefitInputValues);
        }
        setDownloadLink({ available: true, url: getURL('achievableBenefits/download-achievableBenefits-excel', { surveyName }) });
    }, [surveyName, selectedBenefit.type, selectedGroupBy]);

    useEffect(() => {
        const getBenefitInputs = async () => {
            const responseMeasures = await fetchAPI('measures', { surveyName });
            setSelectedMeasures(await responseMeasures.json());

            const responseCurrentBudget = await fetchAPI('currentBudget', { surveyName });
            setIsCurrentBudgetSaved(responseCurrentBudget.ok);

            if (responseMeasures.ok && responseCurrentBudget.ok) {
                const newSetBenefitInputs = Object.fromEntries(
                    await Promise.all(
                        Object.entries(benefitInputs).map(async ([benefit, types]) => [
                            benefit,
                            Object.fromEntries(
                                await Promise.all(
                                    Object.entries(types).map(async ([type, inputs]) =>
                                        [
                                            type,
                                            await Promise.all(
                                                inputs.map(async (input: InputType) => {
                                                    if (input.inputType === 'select') {
                                                        const response = await fetchAPI(
                                                            'achievableBenefits/feinq',
                                                            { type: input.type }
                                                        );

                                                        if (response.ok) {
                                                            input.values = (await response.json())
                                                                .map((v: any) => ({
                                                                    value: v,
                                                                    label: v
                                                                }));
                                                        }
                                                    }

                                                    return input;
                                                })
                                            )
                                        ]
                                    )
                                )
                            )
                        ])
                    )
                );

                setBenefitInputs(newSetBenefitInputs);
            }
        };


        if (surveyName)
            getBenefitInputs();

    }, [surveyName]);

    useEffect(() => {
        setIsFirst(false);
    },  []);

    
    let mount: boolean = true;

    if (isFirst || loading)
        return <Loading />;

    if (mount && Object.keys(selectedMeasures).length === 0) {
        redirect("/measures");
        mount = false;
    }

    if (mount && !isCurrentBudgetSaved) {
        redirect("/current-budget");
        mount = false;
    }

    if (surveyName === null) {
        redirect("/change-propensity");
        mount = false;
    }

    if (!mount)
        return <>Redirect</>;


    return (
        <Card>
            <CardHeader title="Benefici conseguibili" />
            <CardContent>
                {
                    selectedBenefit.groupBy &&
                    <Box>
                        <FormControl fullWidth>
                            <InputLabel id="groupBy-select-label">Raggruppa per</InputLabel>
                            <Select labelId="groupBy-select-label" id="groupBy-select" value={selectedGroupBy} label="Raggruppa per" onChange={handleGroupBy}>
                                {selectedBenefit.groupBy?.map((groupBy: GroupByType) => <MenuItem key={groupBy} value={groupBy}>{groupByLabels[groupBy]}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Box>
                }
                {
                    benefitInputs[selectedBenefit.type]
                    && (
                        <Box className={classes.benefitInputs}>
                            {
                                benefitInputs[selectedBenefit.type]?.general?.map(
                                    (input: InputType) => {
                                        const dataClusters = Object.keys(selectedMeasures);

                                        if (
                                            Array.isArray(input.clusters)
                                            && !input.clusters.find(val => dataClusters.includes(val))
                                        ) {
                                            return null;
                                        }

                                        const onChange = (newValue: any) => {
                                            const newValues = { ...benefitInputValues };
                                            newValues[input.name] = newValue;
                                            setBenefitInputValues(newValues);
                                        };

                                        const { name, label, inputType, values } = input;

                                        return <Input
                                            key={input.name}
                                            type={inputType}
                                            name={name}
                                            label={label}
                                            value={benefitInputValues[input.name]}
                                            onChange={onChange}
                                            values={values}
                                        />;
                                    }
                                )
                            }
                            {
                                benefitInputs[selectedBenefit.type]?.general
                                && Object.keys(benefitInputValues).length > 0
                                && <Button variant="contained" color="primary" onClick={submitInputs} disabled={surveyClosed}>Conferma</Button>
                            }
                        </Box>
                    )
                }
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange}>
                        {
                            Object.entries(benefits).map(([index, benefit]: [string, Benefit]) =>
                                <Tab key={benefit.type} label={benefit.label} {...a11yProps(parseInt(index))} />
                            )
                        }
                    </Tabs>
                </Box>
                <Box>
                    {
                        selectedBenefit.type === 'company' ?
                            <>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><strong>Propensione</strong></TableCell>
                                                {
                                                    benefitInputs[selectedBenefit.type]?.row?.map(input =>
                                                        <TableCell key={input.name}>
                                                            <strong>{input.label}</strong>
                                                        </TableCell>
                                                    )
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                Object.entries(data).map(([cluster, stats], idx) =>
                                                    <TableRow key={idx}>
                                                        <TableCell>{cluster}</TableCell>
                                                        {
                                                            benefitInputs[selectedBenefit.type]?.row?.map(input => {
                                                                const onChange = (newValue: any) => {
                                                                    const newValues = { ...benefitInputRowValues };
                                                                    newValues[cluster] = {
                                                                        ...newValues[cluster],
                                                                        [input.name]: newValue
                                                                    };
                                                                    setBenefitIntputRowValues(newValues);
                                                                };
                                                                const { name, label, inputType, values } = input;
                                                                return <TableCell key={idx}>
                                                                    <Input
                                                                        key={input.name}
                                                                        type={inputType}
                                                                        name={name}
                                                                        label={label}
                                                                        value={benefitInputRowValues?.[cluster]?.[input.name]}
                                                                        onChange={onChange}
                                                                        values={values}
                                                                    />
                                                                </TableCell>;
                                                            })
                                                        }
                                                    </TableRow>
                                                )
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {
                                    benefitInputRowValues
                                    && Object.keys(benefitInputRowValues).length > 0
                                    && <Button variant="contained" color="primary" onClick={saveData} disabled={surveyClosed}>Salva</Button>
                                }
                            </>
                            : <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><strong>Propensione</strong></TableCell>
                                            {
                                                Object.keys(data).length > 0
                                                && Object.keys(Object.values(data)[0]).map(key =>
                                                    <TableCell key={key}><strong>{key}</strong></TableCell>
                                                )
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            Object.entries(data).map(([cluster, stats], idx) =>
                                                <TableRow key={idx}>
                                                    <TableCell>{cluster}</TableCell>
                                                    {
                                                        Object.values(stats).map((value, idx) =>
                                                            <TableCell key={idx}>
                                                                {
                                                                    value
                                                                    && value.toLocaleString(
                                                                        'it',
                                                                        {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2
                                                                        }
                                                                    )
                                                                }
                                                            </TableCell>
                                                        )
                                                    }
                                                </TableRow>
                                            )
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                    }
                </Box>
            </CardContent>
            <CardActions>
                {
                    downloadLink.available
                    && <DownloadExcelButton url={downloadLink.url} />
                }
            </CardActions>
        </Card>
    );
};

export default AchievableBenifits;