import { getURL } from './utils';

const authProvider = {
    // called when the user attempts to log in
    login: async (params: {}) => {
        const response = await fetch(
            getURL('auth/login'), 
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(params),
                credentials: 'include'
            }
        );

        if(!response.ok) {
            return Promise.reject();
        }
        
        // accept all username/password combinations
        return Promise.resolve();
    },
    // called when the user clicks on the logout button
    logout: async () => {
        try {
            await fetch(getURL('auth/logout'), {credentials: 'include'});
        } catch {}
        
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: (status: any) => {
        if (status === 401 || status === 403) {
            return Promise.reject();
        }
        
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: async () => {
        const response = await fetch(getURL('auth/ping'), {credentials: 'include'});
        
        if(response.ok)
            return Promise.resolve();
        
        return Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
};

export default authProvider;