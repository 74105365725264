import { fetchUtils } from 'react-admin'; ''
import jsonServerProvider from 'ra-data-json-server';
import env from 'dotenv';

import { makeStyles } from '@material-ui/core/styles';

import authProvider from './authProvider';



env.config();

export const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    field: {
        marginRight: theme.spacing(5),
    },
    results: {
        marginTop: theme.spacing(5)
    },
    table: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5)
    },
    benefitInputs: {
        '& .MuiFormControl-root ': {
            margin: theme.spacing(1),
            width: '300px',
        },
    },
}));

export const getURL = (path: string | null = null, params: {} | null = null): string => {
    let url = `${process.env.REACT_APP_SERVER_URL}/api/v1`;
    
    if(path !== null) 
        url += `/${path}`;

    if(params === null)
        return url;

    const urlSP = new URLSearchParams(params as Record<string, string>);
    
    return `${url}?${urlSP}`;
}

const fetchJson = (url: string, options = {}) => {
    return fetchUtils.fetchJson(url, {...options, credentials: 'include'});
}

export const JSONServerProvider = jsonServerProvider(getURL(), fetchJson);

export const fetchAPI = async (path: string | null = null, params: {} | null = null, options: {} = {}) => {
    const optionsFetch = {
        ...options,
        credentials: 'include' as RequestCredentials
    };
    
    const response = await fetch(getURL(path, params), optionsFetch);    

    if(!response.ok) {
        try {
            await authProvider.checkError(response.status);
        }
        catch {
            try {
                await authProvider.logout();
            } finally {
                window.location.href = '/';
            }
        }
    }
    return response;
};

