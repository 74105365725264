import React from "react";
import { Admin, Resource } from 'react-admin';

import authProvider from './authProvider';
import dataProvider from './dataProvider';
import Dashboard from './Dashboard';
//import LoginPage from './login';
import CustomRoutes from './customRoutes';
import CustomLayout from './layouts/layout';
import SurveyContext from "./contexts/survey";
import { SurveyType } from "./types";

const App = () => {
    const [survey, setSurvey] = React.useState<SurveyType | null>(null);
    
    const handleSurveychange = (newSurvey: SurveyType) => {
        setSurvey(newSurvey)
        localStorage.setItem('surveyName', newSurvey.name);
    };

    const surveyValue = React.useMemo(() => (
        {survey, setSurvey: handleSurveychange}
    ), [survey]);

    return <SurveyContext.Provider value={surveyValue}>
        <Admin
            layout={CustomLayout}
            dashboard={Dashboard}
            authProvider={authProvider} dataProvider={dataProvider}
            customRoutes={CustomRoutes}
        >
            <Resource name="wip" options={{ label: 'WIP' }} />
        </Admin>
    </SurveyContext.Provider>
};

export default App;