import React from 'react';
import { SurveyContextType } from "../types";

const surveyContextData: SurveyContextType = {
    survey: null,
    setSurvey: (survey: any) => {}
}

const SurveyContext = React.createContext<SurveyContextType>(surveyContextData);

export default SurveyContext;

