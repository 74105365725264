import React from "react";
import { Route } from 'react-router-dom';

//import ProfileEdit from './profile';
import SurveyClosedPage from './pages/listSurveyClosed';
import ChangePropensityPage from './pages/actionChangePropensity';
import MeasuresPage from './pages/actionMeasures';
import CurrentBudgetPage from './pages/actionCurrentBudget';
import AchievableBenefitsPage from './pages/actionAchievableBenefits';



const CustomRoutes = [
    <Route exact path="/surveys-closed" component={SurveyClosedPage} />,
    <Route exact path="/change-propensity" component={ChangePropensityPage} />,
    <Route exact path="/measures" component={MeasuresPage} />,
    <Route exact path="/current-budget" component={CurrentBudgetPage} />,
    <Route exact path="/achievable-benefits" component={AchievableBenefitsPage} />,
    //<Route key="my-profile" path="/my-profile" component={ProfileEdit} />
];

export default CustomRoutes;