import React, { Fragment } from "react";
import { Loading, useRedirect } from 'react-admin';

import {
    Card, CardContent, CardActions, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem,
    FormControl, InputLabel, CardHeader, Box, Tabs, Tab, Typography, OutlinedInput, Checkbox,
    ListItemText, ListItem, ListItemIcon, List, Divider, Collapse, Button
} from '@material-ui/core';
import { ExpandLess, ExpandMore, Add as AddIcon } from '@material-ui/icons';

import Chart from "react-google-charts";

import { a11yProps } from './actionAchievableBenefits';
import Input from '../components/Input';
import DownloadExcelButton from '../components/DownloadExcelButton';

import { 
    ClusterType, SelectedMeasuresType, ActionType, MacroactionType, AxisType,
    ActionInputType, ActionValueType
} from '../types';
import { getURL, fetchAPI } from '../utils';
import SurveyContext from '../contexts/survey';


type MeasuresType = {
    [cluster in ClusterType]: {
        [asix: AxisType]: {
            [macroaction: MacroactionType]: ActionType[]
        }
    }
};

type MeasuresResponseType = (
    {
        PotentialCluster: string;
        TotalPeople: string;
    }
    & { [cluster in ClusterType]: number; }
);

const actionInputInit: ActionInputType = {
    "Perc. uso mezzo": 'number',
    "Dettaglio azione": 'textarea',
    "Costo azione": 'number',
    "A carico dipendente": 'number',
    "A carico azienda": 'number',
    "Incentivi economici": 'number',
    "Ricavi aziendali": 'number',
    "Tempistica di realizzazione inizio": 'date',
    "Tempistica di realizzazione fine": 'date',
    "Nota": 'textarea'
};

type selectedMeasuresPayloadType = {
    surveyName: string;
    measures: SelectedMeasuresType;
};


type HandleActionSelectType = (action: ActionType, macroaction: MacroactionType, selectedActions: ActionType[]) => void;
type AddActionType = (macroaction: MacroactionType) => Promise<void>;
type UpdateBudgetType = (cluster: ClusterType, axis: AxisType, macroaction: MacroactionType, action: ActionType, name: keyof ActionInputType, value: number | string | "") => void;

const MacroactionList = (
    props: {
        macroaction: MacroactionType, actions: ActionType[], selectedActions: ActionType[],
        handleActionSelect: HandleActionSelectType, addAction: AddActionType
    }
): JSX.Element => {
    const { macroaction, actions, selectedActions, handleActionSelect, addAction } = props;
    const [open, setOpen] = React.useState<boolean>(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <List>
            <ListItem onClick={handleClick}>
                <ListItemText primary={macroaction} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding style={{ paddingLeft: 25 }}>
                    {actions.map(action =>
                        <ListItem key={`${macroaction}_${action}_action`} role={undefined} dense button onClick={() => { handleActionSelect(action, macroaction, selectedActions) }}>
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={selectedActions.indexOf(action) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                />
                            </ListItemIcon>
                            <ListItemText primary={action} />

                        </ListItem>
                    )}
                    <ListItem>
                        <ListItemIcon>
                            <AddIcon />
                        </ListItemIcon>
                        <ListItemText primary="Aggiungi nuova azione" onClick={() => { addAction(macroaction) }} />
                    </ListItem>
                </List>
            </Collapse>
        </List>
    );
};

const ActionLists = (
    props: {
        selectedMacroactions: MacroactionType[], selectedMeasures: SelectedMeasuresType
        measures: MeasuresType, cluster: ClusterType, axis: AxisType,
        handleActionSelect: HandleActionSelectType, addAction: AddActionType
    }
): JSX.Element => {
    const { selectedMacroactions, selectedMeasures, measures, cluster, axis, handleActionSelect, addAction } = props;

    const getSelectedActions = (macroaction: MacroactionType): ActionType[] => {
        return Object.keys(selectedMeasures?.[cluster]?.[axis]?.[macroaction] || {});
    };

    const selctedMacroactionsLen = selectedMacroactions.length;
    return (
        <Box>
            {
                selectedMacroactions.map((macroaction, idx) => {
                    const actions = measures[cluster][axis][macroaction];

                    return <Box key={`${macroaction}_actions`}>
                        <MacroactionList
                            macroaction={macroaction}
                            actions={actions}
                            selectedActions={getSelectedActions(macroaction)}
                            handleActionSelect={handleActionSelect}
                            addAction={addAction}
                        />
                        {idx + 1 < selctedMacroactionsLen && <Divider />}
                    </Box>
                })
            }
        </Box>
    );
};

const Budget = (props: { selectedMeasures: SelectedMeasuresType, updateBudget: UpdateBudgetType }): JSX.Element => {
    const { selectedMeasures, updateBudget } = props;
    const percentageVehicleUseClusters = ['Bicicletta/Monopattino', 'Car Pooling', 'Car Sharing', 'Smartworking/Coworking'];

    const ganttChartData = () => {
        const data = Object.keys(selectedMeasures)?.flatMap((cluster) => {
            const clusterKey = cluster as ClusterType;

            return Object.keys(selectedMeasures[clusterKey])?.flatMap((axis: AxisType) => {
                return Object.keys(selectedMeasures[clusterKey][axis])?.flatMap((macroaction: string) => {
                    return Object.keys(selectedMeasures[clusterKey][axis][macroaction])?.map((action: string) => {
                        const startDate = selectedMeasures[clusterKey][axis][macroaction][action]['Tempistica di realizzazione inizio'];
                        const endDate = selectedMeasures[clusterKey][axis][macroaction][action]['Tempistica di realizzazione fine'];

                        if (
                            typeof startDate !== 'string'
                            || typeof endDate !== 'string'
                            || !startDate
                            || !endDate
                        )
                            return [cluster, action];

                        const startDateParsed = new Date(startDate);
                        const endDateParsed = new Date(endDate);

                        return [
                            `${cluster.replace(/\s+/g, '_').toLocaleLowerCase()}__${action.replace(/\s+/g, '_').toLocaleLowerCase()}`,
                            `${action}`,
                            startDateParsed,
                            endDateParsed,
                            null,
                            100,
                            null
                        ];
                    });
                });
            });
        });

        return ([
            [
                { type: 'string', label: 'ID Azione' },
                { type: 'string', label: 'Nome Azione' },
                { type: 'date', label: 'Data Inizio' },
                { type: 'date', label: 'Data Fine' },
                { type: 'number', label: 'Durata' },
                { type: 'number', label: 'Percent Complete' },
                { type: 'string', label: 'Dependencies' },
            ],
            ...data
        ]);
    };
    
    return (
        <Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell variant="head">
                                <Typography component="h5" variant="h5">Asse</Typography>
                            </TableCell>
                            <TableCell variant="head">
                                <Typography component="h5" variant="h5">Azioni</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            Object.entries(selectedMeasures).filter(([_, axes]) => Object.keys(axes).length > 0)
                            .map(([clusters, axes]) => {
                                const cluster = clusters as ClusterType;
                                return <React.Fragment key={`${cluster}_head`}>
                                    <TableRow>
                                        <TableCell colSpan={3} variant="head">
                                            <Typography component="h6" variant="h6">{cluster}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    {
                                        percentageVehicleUseClusters.includes(cluster) &&
                                        <TableRow>
                                            <TableCell colSpan={3} variant="head">
                                                <Input
                                                    value={
                                                        Object.values(Object.values(Object.values(axes)[0] || {})[0] || {})[0]?.['Perc. uso mezzo'] || ""
                                                    }
                                                    type="number"
                                                    onChange={
                                                        (value: string) => {
                                                            const newValue = Number(value);

                                                            Object.entries(axes).filter(([_, macroactions]) => 
                                                                Object.keys(macroactions).length > 0).map(([axis, macroactions]) => {
                                                                    Object.entries(macroactions).filter(([_, actions]) => Object.keys(actions).length > 0).map(([macroaction, actions]) => {
                                                                        Object.entries(actions).map(([action, values]) => {
                                                                            updateBudget(
                                                                                cluster, axis, macroaction, action,
                                                                                'Perc. uso mezzo', newValue
                                                                            )
                                                                        })
                                                                    })
                                                            });
                                                        }
                                                    }
                                                    name="Perc. uso mezzo"
                                                    label="Perc. uso mezzo"
                                                    showLabel={true}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    }
                                    {
                                        Object.entries(axes).filter(([_, macroactions]) => Object.keys(macroactions).length > 0)
                                        .map(([axis, macroactions]) => {
                                            return <TableRow key={`${cluster}_${axis}_macro`}>
                                                <TableCell>{axis}</TableCell>
                                                <TableCell>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell variant="head">
                                                                    <Typography component="h6" variant="h6">Azione</Typography>
                                                                </TableCell>
                                                                {
                                                                    Object.keys(actionInputInit)
                                                                    .filter(name => name !== 'Perc. uso mezzo')
                                                                    .map(name => 
                                                                        <TableCell variant="head" key={`${name}_head`}>
                                                                            <strong>{name}</strong>
                                                                        </TableCell>
                                                                    )
                                                                }
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                Object.entries(macroactions).filter(([_, actions]) => Object.keys(actions).length > 0).map(([macroaction, actions]) =>
                                                                    <React.Fragment key={`${macroaction}_head`} >
                                                                        <TableRow>
                                                                            <TableCell variant="head" colSpan={Object.keys(actionInputInit).length + 1}>
                                                                                <Typography component="h6" variant="h6">{macroaction}</Typography>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        {
                                                                            Object.entries(actions).map(([action, values]) =>
                                                                                <TableRow key={action}>
                                                                                    <TableCell key={`${action}_${action}`}>{action}</TableCell>
                                                                                    {
                                                                                        Object.entries(actionInputInit)
                                                                                        .filter(([name, _]) => name !== 'Perc. uso mezzo')
                                                                                        .map(([name, type]) => {
                                                                                            const value = values[name as keyof ActionInputType];

                                                                                            const onChange = (value: string) => {
                                                                                                const newValue = (type === 'number') ? Number(value) : value;
                                                                                                updateBudget(
                                                                                                    cluster, axis, macroaction, action,
                                                                                                    name as keyof ActionInputType, newValue
                                                                                                )
                                                                                            };

                                                                                            return (
                                                                                                <TableCell key={`${action}_${name}`}>
                                                                                                    <Input
                                                                                                        value={value || ''}
                                                                                                        type={type}
                                                                                                        onChange={onChange}
                                                                                                        name={name}
                                                                                                        label={name}
                                                                                                        showLabel={false}
                                                                                                    />
                                                                                                </TableCell>
                                                                                            );                                                                                            
                                                                                        })
                                                                                    }
                                                                                </TableRow>
                                                                            )
                                                                        }
                                                                    </React.Fragment>
                                                                )
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableCell>
                                            </TableRow>
                                        }
                                        )
                                    }
                                </React.Fragment>;
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <Box>
                {
                    Object.keys(selectedMeasures).length > 0
                    && <Chart
                        width='100%'
                        height='400px'
                        chartType='Gantt'
                        loader={<div>Caricamento Gantt...</div>}
                        data={ganttChartData()}
                        options={{
                            height: 400,
                            gantt: {
                                criticalPathEnabled: false,
                                trackHeight: 50,
                                labelMaxWidth: 500
                            }
                        }}
                    />
                }
                
            </Box>
        </Box>
    );
}


const MeasuresPage = (): JSX.Element => {
    const [value, setValue] = React.useState<number>(0);
    const [measuresData, setMeasuresData] = React.useState<MeasuresResponseType>({} as MeasuresResponseType);
    // const [surveyName] = React.useState<string | null>(localStorage.getItem('surveyName'));
    const [measures, setMeasures] = React.useState<MeasuresType>({} as MeasuresType);
    const [selectedMeasures, setSelectedMeasures] = React.useState<SelectedMeasuresType>({} as SelectedMeasuresType);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [downloadLink, setDownloadLink] = React.useState({
        available: false,
        url: ''
    });
    const { survey } = React.useContext(SurveyContext);

    const surveyName = survey?.name || null;
    const surveyClosed = survey?.closed || false;

    const redirect = useRedirect();

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const addMacroaction = async (cluster: ClusterType, axis: AxisType) => {
        const newMacroaction = prompt("Inserisci la nuova Macroazione");
        if (newMacroaction) {
            const response = await fetchAPI(
                'measures/create/macroaction',
                null,
                {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        params: {
                            axis,
                            propension: cluster,
                        },
                        macroaction: newMacroaction,
                    }),
                }
            );
            if (!response.ok) alert("Qualcosa è andato storto");
            const newSelectedMeasure = { ...selectedMeasures };
            newSelectedMeasure[cluster] = {
                ...newSelectedMeasure?.[cluster],
                [axis]: { ...newSelectedMeasure?.[cluster]?.[axis], [newMacroaction as MacroactionType]: {} }
            };
            const newMesuares = { ...measures };
            newMesuares[cluster][axis][newMacroaction] = [];
            setSelectedMeasures(newSelectedMeasure);
            setMeasures(newMesuares);
        }
    };

    const handleMacroactionSelect = (event: React.ChangeEvent<{ value: unknown; }>, cluster: ClusterType, axis: AxisType) => {
        const values = event.target.value as MacroactionType[];

        const newSelectedMeasures = {
            ...selectedMeasures,
            [cluster]: {
                ...selectedMeasures?.[cluster],
                [axis]: {
                    ...Object.fromEntries(values.filter(v => v !== "__add_new__").map(value => (
                        [value, selectedMeasures?.[cluster]?.[axis]?.[value] || {}]
                    )))
                }
            }
        };

        if (Object.keys(newSelectedMeasures[cluster][axis]).length === 0)
            delete newSelectedMeasures[cluster][axis];

        if (Object.keys(newSelectedMeasures[cluster]).length === 0)
            delete newSelectedMeasures[cluster];

        setSelectedMeasures(newSelectedMeasures);
    };

    const handleActionSelectFactory = (cluster: ClusterType, axis: AxisType) => {
        const ret: HandleActionSelectType = (action, macroaction, selectedActions) => {
            const newSelectedMeasures = { ...selectedMeasures };
            if (selectedActions.includes(action))
                delete newSelectedMeasures[cluster][axis][macroaction][action];
            else {
                const init = Object.fromEntries(
                    Object.keys(actionInputInit).map(key => [key, null])
                ) as ActionValueType;
                newSelectedMeasures[cluster][axis][macroaction][action] = init;
            }

            // if(Object.keys(newSelectedMeasures[cluster][axis][macroaction]).length === 0)
            //     delete newSelectedMeasures[cluster][axis][macroaction];

            if (Object.keys(newSelectedMeasures[cluster][axis]).length === 0)
                delete newSelectedMeasures[cluster][axis];

            if (Object.keys(newSelectedMeasures[cluster]).length === 0)
                delete newSelectedMeasures[cluster];

            setSelectedMeasures(newSelectedMeasures);
        };
        return ret;
    };

    const addActionFactory = (cluster: ClusterType, axis: AxisType) => {
        const ret: AddActionType = async (macroaction: MacroactionType) => {
            const newAction = prompt('Inserisci la nuova azione');
            if (newAction) {
                const response = await fetchAPI('measures/create/action', null, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        params: {
                            axis,
                            propension: cluster,
                        },
                        macroaction: macroaction,
                        action: newAction
                    }),
                });
                if (!response.ok) alert("Qualcosa è andato storto");

                const newSelectedMeasure = { ...selectedMeasures };
                newSelectedMeasure[cluster][axis][macroaction][newAction] = actionInputInit;

                const newMesuares = { ...measures };
                newMesuares[cluster][axis][macroaction].push(newAction);

                setSelectedMeasures(newSelectedMeasure);
                setMeasures(newMesuares);
            }
        };
        return ret;
    };

    const updateBudget: UpdateBudgetType = (cluster, axis, macroaction, action, name, value) => {
        const newSelectedMeasures = { ...selectedMeasures };

        newSelectedMeasures[cluster][axis][macroaction][action] = { 
            ...newSelectedMeasures[cluster][axis][macroaction][action], 
            [name]: value 
        };

        setSelectedMeasures(newSelectedMeasures);
    };

    const getSelectedMacroactions = (cluster: ClusterType, axis: AxisType): MacroactionType[] => {
        return Object.keys(selectedMeasures?.[cluster]?.[axis] || {});
    };

    const isAllActionsSelected = (): boolean => {
        if (Object.keys(selectedMeasures).length === 0) 
            return false;
        
        return Object.values(selectedMeasures).every(axes => (
            Object.values(axes).every(macros => (
                Object.values(macros).every(m => (
                    Object.keys(m).length > 0
                ))
            ))
        ));
    };

    const fetchData = async (): Promise<void> => {
        setLoading(true);
        try {
            const response = await fetchAPI('measures/clusters', { surveyName });

            if (!response.ok) { console.log(response.statusText); return; }

            setMeasuresData(await response.json());
        }
        catch (exception) {
            throw (exception);
        }
        finally {
            setLoading(false);
        }
    };

    const getMeasures = async () => {
        setLoading(true);
        try {
            const response = await fetchAPI('measures');

            if (!response.ok) { console.log(response.statusText); return; }

            setMeasures(await response.json());

            const responseSelected = await fetchAPI('measures', { surveyName });

            if (responseSelected.ok) {
                setSelectedMeasures(await responseSelected.json());
                setDownloadLink({ available: true, url: getURL('measures/download-measures-excel', { surveyName }) });
            }
        }
        catch (exception) {
            throw (exception);
        }
        finally {
            setLoading(false);
        }
    }

    const saveData = async (): Promise<void> => {
        if (surveyName === null) return;
        setLoading(true);
        try {
            const payload: selectedMeasuresPayloadType = { surveyName, measures: selectedMeasures };
            const response = await fetchAPI('measures/create/measures', null, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            });
            if (!response.ok) throw new Error(response.statusText);
            setDownloadLink({ available: true, url: getURL('measures/download-measures-excel', { surveyName }) });
        }
        catch (exception) {
            throw (exception);
        }
        finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        if (surveyName === null)
            redirect("/change-propensity");
        else {
            setMeasuresData({} as MeasuresResponseType);
            fetchData();
            setSelectedMeasures({} as SelectedMeasuresType);
            getMeasures();
        }
    }, [surveyName]);


    if (loading)
        return <Loading loadingPrimary="Attendi..." loadingSecondary="Elaborazione risultati in corso" />;
    
    return (
        <Card>
            <CardHeader title="Misure" />
            <CardContent>
                {
                    measuresData &&
                    <Box>
                        {
                            Object.entries(measuresData)
                                .filter(([cluster]) => !['PotentialCluster', 'TotalPeople'].includes(cluster))
                                .sort((a, b) => {
                                    return Number(b[1]) - Number(a[1]);
                                })
                                .map(([cluster, value]) => {
                                    return (
                                        <Typography key={cluster}>
                                            <strong>
                                                Cluster "{cluster}" con {value} preferenze.
                                            </strong>
                                        </Typography>
                                    );
                                })
                        }
                    </Box>
                }
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    {
                        !isAllActionsSelected() &&
                        <Fragment>
                            <span style={{ color: 'red' }}>
                                Non hai selezionato alcuna macroazione o manca un'azione da scegliere:
                            </span>
                            <ul>
                                {
                                    Object.values(selectedMeasures).map(axes => (
                                        Object.values(axes).map(macros => (
                                            Object.entries(macros).map(([macroaction, actions]) => {
                                                if (Object.keys(actions).length > 0)
                                                    return null;

                                                return (
                                                    <li key={macroaction} style={{ color: 'red' }}>{macroaction}</li>
                                                );
                                            })
                                        ))
                                    ))
                                }
                            </ul>
                        </Fragment>
                    }
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="Progettazione delle misure" {...a11yProps(0)} />
                        <Tab label="Budget azioni e tempistica di realizzazione" {...a11yProps(1)} disabled={ !isAllActionsSelected() } />
                    </Tabs>
                </Box>
                {
                    value === 0 && 
                    <Box>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell variant="head">
                                            <Typography component="h5" variant="h5">
                                                Asse
                                            </Typography>
                                        </TableCell>
                                        <TableCell variant="head">
                                            <Typography component="h5" variant="h5">
                                                Macroazioni
                                            </Typography>
                                        </TableCell>
                                        <TableCell variant="head">
                                            <Typography component="h5" variant="h5">
                                                Azioni
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        Object.entries(measures).map(([clusterS, axes]) => {
                                            const cluster = clusterS as ClusterType;
                                            return <React.Fragment key={`${cluster}_head`}>
                                                <TableRow>
                                                    <TableCell colSpan={3} variant="head">
                                                        <Typography component="h6" variant="h6">{cluster}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                {
                                                    Object.entries(axes).map(([axis, macroactions], aIdx) => {
                                                        const selectedMacroactions = getSelectedMacroactions(cluster, axis);
                                                        return (
                                                            <TableRow key={`${cluster}_${axis}_macro`}>
                                                                <TableCell>{axis}</TableCell>
                                                                <TableCell>
                                                                    <FormControl fullWidth>
                                                                        <InputLabel id={`${aIdx}-select-label`}>Macroazioni</InputLabel>
                                                                        <Select
                                                                            labelId={`${aIdx}-select-label`}
                                                                            id={`${aIdx}-select`}
                                                                            value={selectedMacroactions}
                                                                            label="Macroazioni"
                                                                            onChange={(event) => handleMacroactionSelect(event, cluster, axis)}
                                                                            input={<OutlinedInput label="Macroazione" />}
                                                                            multiple
                                                                            renderValue={(selected: unknown) => {
                                                                                const selectedObj = selected as MacroactionType[];
                                                                                return selectedObj.map((sel) => (`${sel.slice(0, 5)}...`)).join(', ');
                                                                            }}
                                                                        >
                                                                            {
                                                                                Object.keys(macroactions).map((macroaction) => (
                                                                                    <MenuItem key={macroaction} value={macroaction}>
                                                                                        <Checkbox checked={selectedMacroactions.indexOf(macroaction) > -1} />
                                                                                        <ListItemText primary={macroaction} />
                                                                                    </MenuItem>
                                                                                ))
                                                                            }
                                                                            <MenuItem value="__add_new__" onClick={() => { addMacroaction(cluster, axis); }}>
                                                                                <ListItemIcon><AddIcon /></ListItemIcon>
                                                                                <ListItemText primary="Aggiungi nuova macroazione" />
                                                                            </MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <ActionLists
                                                                        measures={measures}
                                                                        cluster={cluster}
                                                                        axis={axis}
                                                                        selectedMacroactions={selectedMacroactions}
                                                                        selectedMeasures={selectedMeasures}
                                                                        handleActionSelect={handleActionSelectFactory(cluster, axis)}
                                                                        addAction={addActionFactory(cluster, axis)}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })
                                                }
                                            </React.Fragment>
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {/* TEST fix chart in tab */}
                        <div style={{display: 'none'}}>
                            <Chart
                                chartType="Table"
                                width="100%"
                                height="400px"
                                data={[]}
                            />
                        </div>
                    </Box>
                }
                {
                    value === 1 && <Budget selectedMeasures={selectedMeasures} updateBudget={updateBudget} />
                }
            </CardContent>
            <CardActions>
                <Button variant="contained" color="primary" onClick={saveData} disabled={surveyClosed}>Salva</Button>
                {
                    downloadLink.available
                    && <DownloadExcelButton url={ downloadLink.url } />
                }
            </CardActions>
        </Card>
    );
};

export default MeasuresPage;
