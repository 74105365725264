import React from "react";
import {
    Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    CardHeader, Typography, Button, Box
} from '@material-ui/core';
import { Loading } from 'react-admin';

import { fetchAPI } from '../utils';
import SurveyContext from "../contexts/survey";
import { SurveyContextType } from "../types";



type SurveysType = {
    id: string;
};

const SurveyClosedPage = () => {
    const surveyCurrent = React.useContext<SurveyContextType>(SurveyContext);
    const [surveysClosed, setSurveysClosed] = React.useState<SurveysType[]>([]);
    const [loading, setLoading] = React.useState<boolean>(true);

    React.useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const response = await fetchAPI('surveys/closed');

            if(response.ok) 
                setSurveysClosed(await response.json());
            
            setLoading(false);
        };

        fetchData();
    }, []);


    const getData = (surveyName: string) => {
        surveyCurrent.setSurvey({
            name: surveyName,
            closed: true,
        });
    };

    if(loading)
        return <Loading />;


    return (
        <Card>
            <CardHeader title="Sondaggi chiusi"/>
            <CardContent>
                {
                    loading === false && surveysClosed.length === 0 ?
                    <Box>Non ci sono sondaggi chiusi al momento</Box> :
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell variant="head">
                                        <Typography component="h5" variant="h5">
                                            Nome
                                        </Typography>
                                    </TableCell>
                                    <TableCell variant="head"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    Object.keys(surveysClosed).length > 0
                                    && surveysClosed.map(survey => (
                                        <TableRow key={survey.id}>
                                            <TableCell>{survey.id}</TableCell>
                                            <TableCell>
                                                <Button 
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => getData(survey.id)}
                                                    disabled={survey.id === surveyCurrent?.survey?.name}
                                                >
                                                    Carica
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
                
            </CardContent>
        </Card>
    );
};

export default SurveyClosedPage;