import React from 'react';
import { useSelector } from 'react-redux';
import { DashboardMenuItem, MenuItemLink, getResources, Menu, MenuProps, useAuthState, Loading } from 'react-admin';
import { ReduxState } from 'ra-core';
import { CardMembership, ViewList, Lock } from '@material-ui/icons';
import { FormControl, Select, InputLabel, OutlinedInput, MenuItem, Button, Tooltip } from '@material-ui/core';

import { fetchAPI } from '../utils';
import SurveyContext from "../contexts/survey";
import { SurveyContextType } from "../types";



type SurveysType = {
    id: string;
};

const SurveySelector = () => {
    const surveyCurrent = React.useContext<SurveyContextType>(SurveyContext);
    const [surveys, setSurveys] = React.useState<SurveysType[]>([]);

    React.useEffect(() => {
        const fetchData = async () => {
            const response = await fetchAPI('surveys');

            if(response.ok)
                setSurveys(await response.json());
        };

        fetchData();
    }, [surveyCurrent?.survey]);


    const onChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const { value } = event.target;
        surveyCurrent.setSurvey({
            name: value as string,
            closed: false,
        });
    };

    return (
        <React.Fragment>
            {
                surveyCurrent?.survey?.closed === true
                && 
                <Tooltip title={surveyCurrent.survey.name}>
                    <Button size="small" startIcon={<Lock fontSize="inherit"/>}>
                        {surveyCurrent.survey.name}
                    </Button>
                </Tooltip>
            }
            <FormControl fullWidth>
                <InputLabel id="survey-select-label">Seleziona sondaggio</InputLabel>
                <Select
                    labelId="survey-select-label"
                    id="survey-select"
                    value={surveyCurrent.survey?.name || ""}
                    label="Sondaggi"
                    onChange={onChange}
                    input={<OutlinedInput label="Sondaggio" />}
                >
                    <MenuItem value="" disabled={true}></MenuItem>
                    {
                        surveys.map(survey => 
                            <MenuItem key={survey.id} value={survey.id}>
                                {survey.id.replace(/__/g, ", ").replace(/_/g, " ")}
                            </MenuItem>
                        )
                    }
                </Select>
            </FormControl>

        </React.Fragment>
    );
};

const CustomMenu = (props: MenuProps) => {
    const { loading, authenticated } = useAuthState();
    const resources = useSelector(getResources);
    const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);


    if(loading) {
        return <Loading />;
    }

    return (
        <Menu {...props}>
            <DashboardMenuItem />
            { 
                authenticated && open
                && <div style={{padding: "16px 6px"}}>
                    <SurveySelector />
                </div>
            }
            <MenuItemLink to="/surveys-closed" primaryText="Sondaggi chiusi" leftIcon={<CardMembership />} />
            <MenuItemLink to="/change-propensity" primaryText="Prop. al cambiamento" leftIcon={<CardMembership />} />
            <MenuItemLink to="/measures" primaryText="Misure" leftIcon={<CardMembership />} />
            <MenuItemLink to="/current-budget" primaryText="Bilancio attuale" leftIcon={<CardMembership />} />
            <MenuItemLink to="/achievable-benefits" primaryText="Benefici conseguibili" leftIcon={<CardMembership />} />
            {resources.filter(resource => resource.name != 'wip').map(resource => (
                <MenuItemLink
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={(resource.options && resource.options.label) || resource.name}
                    leftIcon={resource.icon ? <resource.icon /> : <ViewList />}
                    sidebarIsOpen={true}
                />
            ))}
            <footer style={{
                position: 'fixed',
                right: 0,
                bottom: 0,
                left: 0,
                zIndex: 100,
                padding: 6
                }}
            >
                <img src="/logo.png" alt="logo" />
            </footer>
        </Menu>
    );
};

export default CustomMenu;