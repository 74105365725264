import { Button as ButtonMui } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles((theme) => ({
    buttonDownloadExcel: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: '#ffffff',
        backgroundColor: '#2e7d32'
    }
}));

const DownloadExcelButton = (props: { url: string }) => {
    const classes = useStyles();

    return (
        <ButtonMui className={ classes.buttonDownloadExcel } href={ props.url } target="_blank">
            Scarica Excel
        </ButtonMui>
    );
};

export default DownloadExcelButton;