import React, { useState, useEffect } from "react";
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { 
    fetchEnd, fetchStart, SelectInput, NumberInput, Button, useNotify,
    useQueryWithStore, Loading
} from 'react-admin';
import { Card, CardContent, CardHeader, Box, Typography } from '@material-ui/core';
import Chart from "react-google-charts";

import DownloadExcelButton from '../components/DownloadExcelButton';
import { FreeObject, DownloadLink } from '../types';
import { getURL, fetchAPI, useStyles } from '../utils';
import SurveyContext from '../contexts/survey';


const ChangePropensityPage = () => {
    const dispatch = useDispatch();
    const notify = useNotify();

    const [changePropensityData, setChangePropensityData] = useState<FreeObject>({
        filterSex: ''
    });
    const [changePropensityResponse, setChangePropensityResponse] = useState<FreeObject>({});
    const [downloadLink, setDownloadLink] = useState<DownloadLink>({
        available: false,
        url: ''
    });
    const [loadingResults, setLoadingResults] = useState(false);
    const { survey } = React.useContext(SurveyContext);

    const surveyName = survey?.name || null;
    const surveyClosed = survey?.closed || false;

    const classes = useStyles();
    
    const { loading, error, data } = useQueryWithStore({
        type: 'getList',
        resource: 'surveys',
        payload: {
            pagination: {
                page: 1,
                perPage: 100
            },
            sort: {
                field: 'TABLE_NAME',
                order: 'ASC'
            }
        }
    });

    const onChange = (e: any) => {
        setChangePropensityData({ ...changePropensityData, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        const getData = async () => {
            const response = await fetchAPI('changePropensity', { surveyName });

            if(response.ok) {
                const data = await response.json();
                setChangePropensityData(data.commonData);
                setChangePropensityResponse(data.changePropensityData);
                setDownloadLink({
                    available: true,
                    url: getURL('changePropensity/download-changePropensity-excel', { surveyName })
                });
            }
        }

        if (surveyName !== null) {
            setChangePropensityData({});
            getData();
        }
    }, [surveyName]);

    const submit = async () => {
        dispatch(fetchStart());
        setLoadingResults(true);
        try {

            const response = await fetchAPI('changePropensity', null, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({...changePropensityData, surveyName}),
            });

            const result = await response.json();

            setChangePropensityResponse(result);

            setDownloadLink({
                available: true,
                url: getURL('changePropensity/download-changePropensity-excel', { surveyName })
            });
        }
        catch (error: any) {
            notify(error, 'warning', undefined, false, 5000);
            console.error(error);
        }
        setLoadingResults(false);
        dispatch(fetchEnd());
    };

    const propensityAnswersChartData = ((type: string, color: string) => {
        const data = Object.entries<number>(changePropensityResponse[type])?.map(([answer, percentage]) => (
            [answer, percentage, color, `${percentage}%`]
        ));

        return ([
            [
                'Risposta',
                'Dato (%)',
                { role: 'style' },
                { role: 'annotation' }
            ],
            ...data
        ]);
    });

    return (
        <Card>
            <CardHeader title="Propensione al cambiamento" />
            <CardContent>
                <Box>
                    <Form onSubmit={submit} initialValues={{ ...changePropensityData }}>
                        {props => (
                            <form onSubmit={props.handleSubmit}>
                                <Typography component="h6" variant="h6">Seleziona il genere</Typography>
                                <SelectInput
                                    label="Seleziona"
                                    source="filterSex"
                                    onChange={onChange}
                                    choices={[
                                        { id: 'A', name: 'Tutti' },
                                        { id: 'M', name: 'Maschio' },
                                        { id: 'F', name: 'Femmina' }
                                    ]}
                                    disabled={loading || loadingResults || surveyClosed}
                                    required
                                />
                                <Typography component="h6" variant="h6">Filtri (Opzionali)</Typography>
                                <Box className={classes.root}>
                                    <SelectInput 
                                        className={classes.field}
                                        label="Filtra per veicolo"
                                        source="filterVehicleType"
                                        onChange={onChange}
                                        allowEmpty
                                        choices={[
                                            { id: 'privateCarAndCompanyCar', name: 'Auto + Auto aziendale' },
                                            { id: 'motorcycle', name: 'Moto/Scooter' },
                                            { id: 'privateCarAndCompanyCarAndMotorcycle', name: 'Auto + Auto aziendale + Moto'}
                                        ]}
                                        disabled={loading || loadingResults || surveyClosed}
                                    />
                                    <NumberInput
                                        className={classes.field}
                                        label="Filtra per spesa media (Min)"
                                        source="averageExpenseRangeMin"
                                        onChange={onChange}
                                        min={0}
                                        step={1}
                                        disabled={loading || loadingResults || surveyClosed}
                                    />
                                    <NumberInput
                                        className={classes.field}
                                        label="Filtra per spesa media (Max)"
                                        source="averageExpenseRangeMax"
                                        onChange={onChange}
                                        min={0}
                                        step={1}
                                        disabled={loading || loadingResults || surveyClosed}
                                    />
                                    <NumberInput 
                                        className={classes.field}
                                        label="Filtra per distanza (Min)"
                                        source="distanceRangeMin"
                                        onChange={onChange}
                                        min={0}
                                        step={1}
                                        disabled={loading || loadingResults || surveyClosed}
                                    />
                                    <NumberInput 
                                        className={classes.field}
                                        label="Filtra per distanza (Max)"
                                        source="distanceRangeMax"
                                        onChange={onChange}
                                        min={0}
                                        step={1}
                                        disabled={loading || loadingResults || surveyClosed}
                                    />
                                </Box>
                                <Button label="Conferma" type="submit" size="large" variant="contained" disabled={loading || loadingResults || surveyClosed} />
                            </form>
                        )}
                    </Form>
                    { loadingResults && <Loading loadingPrimary="Attendi..." loadingSecondary="Elaborazione risultati in corso" /> }
                </Box>
                <Box className={classes.results}>
                    {
                        downloadLink.available
                        && <DownloadExcelButton url={ downloadLink.url } />
                    }
                    {
                        Object.keys(changePropensityResponse).length > 0 &&
                        <Box>
                            <Chart
                                key = 'totPercentage'
                                width ='100%'
                                height ='400px'
                                chartType ="BarChart"
                                loader ={<div>Caricamento grafico...</div>}
                                data = {[
                                    [
                                        'Risposta',
                                        'Dato (%)',
                                        { role: 'style' },
                                        { role: 'annotation' }
                                    ],
                                    [
                                        'Si',
                                        changePropensityResponse.totPercentagePositive,
                                        '#0ca73f',
                                        `${changePropensityResponse.totPercentagePositive}%`
                                    ],
                                    [
                                        'No',
                                        changePropensityResponse.totPercentageNegative,
                                        '#f04242',
                                        `${changePropensityResponse.totPercentageNegative}%`
                                    ]
                                ]}
                                options = {{
                                    title: 'I dipendenti sono propensi al cambiamento ?',
                                    titleTextStyle: {
                                        fontName: 'Arial',
                                        fontSize: 20,
                                        bold: true
                                    },
                                    chartArea: { width: '75%' },
                                    hAxis: {
                                        title: 'Dipendenti (%)',
                                        minValue: 0,
                                        maxValue: 100
                                    },
                                    vAxis: {
                                        title: '',
                                    },
                                    bars: 'horizontal',
                                    legend: 'none',
                                    animation: {
                                        duration: 500,
                                        easing: 'out',
                                        startup: true,
                                    },
                                }}
                            />
                            <Chart
                                key = 'percentageByVehicles'
                                width = '100%'
                                height = '400px'
                                chartType = "BarChart"
                                loader = {<div>Caricamento grafico...</div>}
                                data={[
                                    [
                                        'Risposta',
                                        'Dato (%)',
                                        { role: 'style' },
                                        { role: 'annotation' }
                                    ],
                                    [
                                        'Trasporto Pubblico',
                                        changePropensityResponse.percentagePositiveByVehicle['Trasporti Pubblici'],
                                        '#003f5c',
                                        `${changePropensityResponse.percentagePositiveByVehicle['Trasporti Pubblici']}%`
                                    ],
                                    [
                                        'Bicicletta/Monopattino',
                                        changePropensityResponse.percentagePositiveByVehicle['Bicicletta/Monopattino'],
                                        '#58508d',
                                        `${changePropensityResponse.percentagePositiveByVehicle['Bicicletta/Monopattino']}%`
                                    ],
                                    [
                                        'Car Pooling',
                                        changePropensityResponse.percentagePositiveByVehicle['Car Pooling'],
                                        '#bc5090',
                                        `${changePropensityResponse.percentagePositiveByVehicle['Car Pooling']}%`
                                    ],
                                    [
                                        'Car Sharing',
                                        changePropensityResponse.percentagePositiveByVehicle['Car Sharing'],
                                        '#ff6361',
                                        `${changePropensityResponse.percentagePositiveByVehicle['Car Sharing']}%`
                                    ],
                                    [
                                        'Navetta Aziendale',
                                        changePropensityResponse.percentagePositiveByVehicle['Navetta aziendale'],
                                        '#ffa600',
                                        `${changePropensityResponse.percentagePositiveByVehicle['Navetta aziendale']}%`
                                    ],
                                    [
                                        'Smartworking/Coworking',
                                        changePropensityResponse.percentagePositiveByVehicle['Smartworking/Coworking'],
                                        '#ffa600',
                                        changePropensityResponse.percentagePositiveByVehicle['Smartworking/Coworking'],
                                    ]
                                ]}
                                options = {{
                                    title: 'Cosa hanno scelto i dipendenti ?',
                                    titleTextStyle: {
                                        fontName: 'Arial',
                                        fontSize: 20,
                                        bold: true
                                    },
                                    chartArea: { width: '75%' },
                                    hAxis: {
                                        title: 'Dipendenti disposti a cambiare (%)',
                                        minValue: 0,
                                        maxValue: 100
                                    },
                                    vAxis: {
                                        title: '',
                                    },
                                    bars: 'horizontal',
                                    legend: 'none',
                                    animation: {
                                        duration: 500,
                                        easing: 'out',
                                        startup: true,
                                    },
                                }}
                            />
                            {
                                Object.keys(changePropensityResponse).map((stat: string) => {
                                    let title = '';
                                    let color = '';

                                    switch (stat) {
                                        case 'percentagePublicTransport':
                                            title = 'Trasporto Pubblico';
                                            color = '003f5c';
                                            break;
                                        case 'percentageBicycle':
                                            title = 'Bicicletta/Monopattino';
                                            color = '58508d';
                                            break;
                                        case 'percentageVehiclePooling':
                                            title = 'Car Pooling';
                                            color = 'bc5090';
                                            break;
                                        case 'percentageVehicleSharing':
                                            title = 'Car Sharing';
                                            color = 'ff6361'
                                            break;
                                        case 'percentageCompanyShuttle':
                                            title = 'Navetta Aziendale';
                                            color = 'ffa600';
                                            break;
                                        case 'percentageSmartworkingCoworking':
                                            title = 'Smart-working/Co-working';
                                            color = 'ffa600';
                                            break;
                                    }

                                    return title && (
                                        <Chart
                                            key = {stat}
                                            width = '100%'
                                            height = '400px'
                                            chartType = "BarChart"
                                            loader = {<div>Caricamento grafico...</div>}
                                            data = {propensityAnswersChartData(stat, color)}
                                            options = {{
                                                title: title,
                                                titleTextStyle: {
                                                    fontName: 'Arial',
                                                    fontSize: 20,
                                                    bold: true
                                                },
                                                chartArea: { width: '50%' },
                                                hAxis: {
                                                    title: 'Dipendenti (%)',
                                                    minValue: 0,
                                                    maxValue: 100
                                                },
                                                vAxis: {
                                                    title: '',
                                                },
                                                bars: 'horizontal',
                                                legend: 'none',
                                                animation: {
                                                    duration: 500,
                                                    easing: 'out',
                                                    startup: true,
                                                },
                                            }}
                                        />
                                    );
                                })
                            }
                        </Box>
                    }
                </Box>
            </CardContent>
        </Card>
    );
}

export default ChangePropensityPage;
