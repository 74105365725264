import {
    MenuItem, Select, TextField, TextareaAutosize, FormControl, InputLabel,
} from '@material-ui/core';

import { InputValuesType } from '../types';

const Input = (props: {
    type: string, name: string, value: string | number, 
    onChange: (value: any) => void, showLabel?: boolean, label?: string, values?: InputValuesType[]
}): JSX.Element => {
    const { name, label, type, value, onChange, showLabel=true, values, ...other } = props;
    const key = `${type}_input_${name}`;

    const handleOnChange = (event: React.ChangeEvent<{ value: any; }>) => onChange(event.target.value);
    switch(type) {
        case 'number':
            return <TextField
                type="number"
                value={value || ""}
                onChange={handleOnChange}
                label={showLabel ? label : null}
                {...other}
            />;
            
        case 'select':
            return <FormControl>
                <InputLabel id={key}>{label}</InputLabel>
                <Select
                    labelId={key}
                    value={value || ''}
                    label={showLabel ? label : null}
                    onChange={handleOnChange}
                    {...other}
                >
                    {
                        values?.map(
                            (option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            )
                        )
                    }
                </Select>
            </FormControl>;

        case 'textarea':
            let placeholder = undefined;
            if(showLabel && label) {
                placeholder = label;
            }

            return <TextareaAutosize
                value={value || ""}
                onChange={handleOnChange}
                placeholder={placeholder}
                {...other}
            />;
            
    }

    return <TextField
        type={type}
        value={value || ""}
        onChange={handleOnChange}
        label={showLabel ? label : null}
        {...other}
    />;
};

export default Input;
